@use "./abstracts/" as *;
@import url(../assets/fonts/css/fontiran.css);

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;500&display=swap");

:root {
  --primary-green: #4ad000; /* Define your green color */
  --hover-green: #5bff00;   /* Define a slightly different green for hover */
  --two-factor-color: #FFB505;// #B388EB;
  --lock-bet-color: #c9d266;
  --freeze-account: #ed00f3;
  --back-bet-color: #93C2FD;//#3790fb;
  --lay-bet-color: #E79AFA;//#d141f6;
  --red-color: #b40415;
  --green-color: #00611b;
  --muiTableFontSize: 0.8rem;
}

/* Media query for mobile screens */
@media (max-width: 767px) {
  :root {
    --muiTableFontSize: 0.7rem;
  }
}

html {
  /* ****Colors**** */
  --primaryColor: #0054B5;//#3A53B8;//#003674;// #0054B5; //#005ECA;
  --secondaryColor: #3c4b6d; 
  //light theme
  --body-bg: #FFFFFF;
  --bgc: #FFFFFF;// #EAEAEA;
  --card-bgc: #f2f2f2;
  --card-bgc1: #ffffff;
  --text-color: #424750;
  --shadowBgColor:  #0003;
  //light theme status
  --approved-bgc: rgba(86, 240, 0, 0.3);
  --approved-textColor: #246500;
  --pending-bgc: rgba(45, 204, 255, 0.3);
  --pending-textColor: #086785;
  --rejected-bgc: rgba(255, 56, 56, 0.3);
  --rejected-textColor: #9d0e0e;
  --disabled-btnColor: #d3d3d3;
  --login-input-bg: #C4C4C49E;

  /* ****Font family**** */
  --fontFamily: "Poppins", sans-serif;
}
html[theme="dark"] {
  //dark theme
  --body-bg: #4C4C4C;
  --primaryColor: #0054B5;//#3A53B8;//#005ECA;
  --secondaryColor: #3c4b6d;
  --bgc: #283036;//#000000;
  --card-bgc: #1e2529;
  --card-bgc1: #121212;
  --text-color: #ebebeb;
  --shadowBgColor: #c1c1c133;
  //light theme status
  --approved-bgc: rgba(66, 171, 8, 0.3);
  --approved-textColor: #d0ffb6;
  --pending-bgc: rgba(45, 204, 255, 0.3);
  --pending-textColor: #a5e9ff;
  --rejected-bgc: rgba(255, 56, 56, 0.3);
  --rejected-textColor: #f2bebe;
  --disabled-btnColor: #5f5f5f;
  --login-input-bg: #53565E;

}

html[dir="rtl"] {
  --fontFamily: iranyekan, "IRANSans", "Tahoma";
}

// body {
//   user-select: none;  /* Disables text selection */
// }

//reset
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

input,
select {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  &:focus {
    background-color: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  color: #b9b9b9;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

//general styles
body {
  background-color: var(--bgc);
  color: var(--text-color);
  font-family: var(--fontFamily);
  font-size: $fontSizeBodyMedium;
  line-height: 1.5em;
  overflow-x: auto;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @include tablet {
    background-color: var(--secondaryColor);
  }
}

.title {
  font-size: $fontSizeHeadlineSmall;
  font-weight: 700;
  line-height: 2em;
  margin-bottom: 2rem;
}

.subTitle {
  font-size: 18px;
  margin-bottom: 1rem;
}

.status {
  display: inline-block;
  padding: 2px 10px;
  text-align: center;
  font-size: 10px;
  border-radius: $largeBorderRadius;
}
.approved {
  background-color: var(--approved-bgc);
  color: var(--approved-textColor);
}

.pending {
  background-color: var(--pending-bgc);
  color: var(--pending-textColor);
}

.rejected {
  background-color: var(--rejected-bgc);
  color: var(--rejected-textColor);
}

.ltr {
  direction: ltr;
  text-align: left;
}

.rtl {
  font-family: iranyekan, "IRANSans", "Tahoma";
  direction: rtl;
  text-align: right;
}

.error {
  color: var(--rejected-textColor);
}

@include tablet {
  .topNav_left_menu_open {
    display: block;
  }

  .topNav_left_menu_close {
    display: none;
  }
}

@include tablet {
  .sidebar__open .main_wrapper {
    height: 100vh;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transform: scale(1) translateX(30%);
    transition: all 0.5s ease-in-out;
    box-shadow: -3px 8px 6px rgba(0, 0, 0, 0.3);
  }

  .sidebar__open .topNav_left_menu_open {
    display: none;
  }

  .sidebar__open .topNav_left_menu_close {
    display: block;
  }
}

@include mobile {
  .sidebar__open .main_wrapper {
    transform: scale(1) translateX(50%);
  }
}

#overlay-root > div {
  padding: 0;
}

.main_wrapper {
  transform: scale(1) translateX(0);
  // transition: all 0.5s ease-in-out;
}

//----------- not found Page -----------------
.notFound__container {
  font-family: var(--fontFamily);
  @include flex();
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  @include tablet {
    background-color: var(--bgc);
  }
}

.notFound__container_rtl {
  font-family: iranyekan, "IRANSans", "Tahoma";
  min-height: 100vh;
  @include flex();
  flex-direction: column;
  @include tablet {
    background-color: var(--bgc);
  }
}

.notFound__title {
  font-size: 63px;
  margin: 5rem 0 3rem;
  font-family: inherit;
  line-height: 70px;
  &_rtl {
    direction: rtl;
  }

  @include tablet {
    font-size: 36px;
    margin: 3rem;
    line-height: 33px;
  }
  @include mobile {
    font-size: 24px;
    margin: 1rem;
  }
}

.notFound__img {
  max-width: 500px;
  margin: 0 auto;
}

//---------- end of not Found page

html[dir="rtl"] {
  @include tablet {
    .sidebar__open .main_wrapper {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      transform: scale(0.9) translateX(-45%);
    }
  }
}

//---------- scrollbar styles

::-webkit-scrollbar {
  width: 0.4rem;
  height: 0.6rem;
}

::-webkit-scrollbar-track {
  background-color: var(--bgc);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondaryColor);
  border-radius: 100vw;
}

// generic form css

.button_Wrapper {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.customBtn {
  border-radius: 8px !important;
  min-width: 100px !important;
}

.topButtonsWrapper {
  margin-bottom: 16px;
  display: flex;
  justify-content: flex-end;
}

.debit-cell {
  color: var(--rejected-textColor);
}

.credit-cell {
  color: var(--approved-textColor);
}

.back-bet {
  background-color: var(--back-bet-color);
}

.lay-bet {
  background-color: var(--lay-bet-color);
}

@keyframes blink {
  0% {
    background-color: initial;
  }
  50% {
    background-color: rgb(255, 255, 0);
  } /* Change 'yellow' to any color you like */
  100% {
    background-color: initial;
  }
}

.blink {
  animation: blink 2s;
}

.bold-header {
  font-weight: bold;
}

.inputWrapper {
  width: 50%;
}

.buttonsWrapper {
  display: flex;
  gap: 4px;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.inputBox {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  // /* Firefox */
  // input[type=number] {
  //   -moz-appearance: textfield;
  // }
}

.breadcrumbWrapper {
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  background: var(--bgc);
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.total-row {
  background-color: lightblue;
  color: black; 
  font-weight: bold;
}


// Hide scroll bar from mobile
@media (max-width: 700px) {
  ::-webkit-scrollbar {
    display: none;
  }
  body, html {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}