@use "../../../scss/abstracts/" as *;

html {
  background-color: var(--bgc);
}

.fullscreenBg {
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
}

.container {
  height: 100vh;
  background-color: var(--bgc);
  position: relative;
  overflow: hidden;
  & .logoWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .logo {
    margin-top: 1rem;
    margin-bottom: 2rem;
    width: 235px;
    max-width: 235px;
    @media screen and (max-width: 767px) {
      width: 170px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
      width: 200px;
    }
  }

  & .innerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
  }

  & .wrapper {
    // width: 65%;
    // position: relative;
    @media screen and (max-width: 767px) {
      width: 90%;
      max-width: 400px;
    }
  }
}

.loginBox {
  @include flex();
  flex-direction: column;
  width: 500px;
  padding: 30px;
  background-color: #fff;
  color: #424750;
  border-radius: $mediumBorderRadius;
  border-radius: 16px;
  // box-shadow: 0 4px 30px rgba(var(--bgc), 0.1);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  position: relative;
  background: var(--bgc);//rgba(var(--bgc), 0.8);
  // opacity: 0.85;
  -webkit-box-shadow: -1px -1px 24px -9px rgba(128, 118, 128, 1);
  -moz-box-shadow: -1px -1px 24px -9px rgba(128, 118, 128, 1);
  box-shadow: 6px 10px 10px rgba(0, 0, 0, 0.4);

  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 400px;
  }

  .title {
    font-size: 15px;
    margin-top: -17px;
    letter-spacing: 0;
    font-weight: 700;
    margin-bottom: 30px;
    // margin-top: 50px;
    // line-height: 50px;
    text-align: center;
    width: 100%;
    color: var(--primaryColor);

    // @media screen and (max-width: 767px) {
    //   font-size: $fontSizeHeadlineSmall;
    //   margin-bottom: 24px;
    //   margin-top: 0px;
    // }

    // @media (min-width: 768px) and (max-width: 1024px) {
    //   font-size: 34px;
    //   margin-bottom: 24px;
    //   margin-top: 0px;
    // }
  }

  .errorMessage {
    position: relative;
    font-size: 14px;
    color: red;
    top: -25px;
    display: none;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
  }

  button {
    display: block;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .forgat_pass {
    margin: 1rem 0;
    display: inline-block;
    color: gray;
    font-size: 14px;
  }

  .checkbox {
    @include flex(center, flex-start);

    input {
      display: inline-block;
      width: 20px;
      height: 20px;
      padding: 6px;
      border-radius: 8px;
    }

    label {
      margin: 0 0.5rem;
    }
  }

  .otpTopContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-top: 100px;
    gap: 8px;
    img {
      width: 100px;
      margin-bottom: 15px;
    }

    .content {
      font-size: $fontSizeHeadlineSmall;
      color: var(--text-color);
    }

    .textContent {
      max-width: 300px;
      width: 300px;
      margin-top: 24px;
      text-align: center;
      margin-bottom: 25px;
      color: var(--text-color);
    }
  }

  .loginInput {
    // background-color: var(--login-input-bg);
    color: var(--text-color);
  }
  .rememberMe {
    color: var(--text-color);
  }
}

.rtl {
  font-family: iranyekan, "IRANSans", "Tahoma";
  direction: rtl;
  text-align: right;

  label {
    left: auto;
    right: 10px;
  }
}

.loginRightBg {
  position: absolute;
  bottom: 63px;
  right: -60px;
  width: 105px;
  z-index: 0;
}

.loginRingLeft {
  position: absolute;
  width: 100px;
  left: -58px;
  z-index: 0;
  bottom: 72px;
}

.rightTop {
  position: absolute;
  width: 100px;
  right: -60px;
  top: 0;
}

.loginRightImage {
  position: absolute;
  width: 500px;
  right: 0;
  bottom: 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 200px;
  }
}

.leftBlur {
  background: radial-gradient(
    circle,
    rgba(0, 123, 255, 1) 0%,
    rgba(0, 123, 255, 0.7) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  width: 1000px;
  height: 1000px;
  position: absolute;
  border-radius: 50%;
  left: -463px;
  top: -554px;
  filter: blur(12px);
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 300px;
    height: 300px;
    left: -50px;
    top: -37px;
  }
}

.rightBlur {
  background: radial-gradient(
    circle,
    rgba(255, 223, 0, 1) 0%,
    rgba(255, 223, 0, 0.8) 30%,
    rgba(255, 223, 0, 0.6) 50%,
    rgba(255, 223, 0, 0) 80%
  );
  width: 900px;
  height: 900px;
  position: absolute;
  bottom: -460px;
  right: -290px;
  border-radius: 50%;
  filter: blur(20px);
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    width: 250px;
    height: 250px;
    bottom: -80px;
    right: -42px;
  }
}

.loginBoxWrapper {
  position: relative;
  width: 500px;
  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 400px;
  }
}

.otpInput {
  input {
    width: 28px;
    text-align: center;
    padding: 8px;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.resendOtp {
  margin-top: 15px;
  color: var(--text-color);
  span {
    color: var(--primaryColor);
    cursor: pointer;
  }
}

.rememberMe {
  color: var(--text-color);
}

.submitBtn {
  padding: 16px !important;
  margin-top: 30px !important;
  text-transform: capitalize;
  margin-bottom: 35px !important;
  font-size: 16px !important;
  color: #ffffff !important;
}
