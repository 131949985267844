.modalContentWrapper {
  background-color: var(--bgc);
  position: absolute;
  top: 50%;
  left: 50%;
  // height: 90%;
  transform: translate(-50%, -50%);
  border-radius: 18px;
  // border: 3px solid var(--primaryColor);
  box-shadow: 0 4px 14px var(--shadowBgColor);
  padding: 30px;
  @media (max-width: 768px) {
    padding: 30px 10px;
    border-radius: 12px;
  }
}

.closeButtonIcon {
  position: absolute;
  right: 5px;
  top: 5px;
}

.modalTitleWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  color: var(--primaryColor);
  font-size: 20px;
  position: relative;
}

.innerModalWrapper {
  overflow-y: scroll;
}

.modalTitle {
  font-weight: 600;
  padding: 0 16px;
  font-size: 1.7em;
  margin-bottom: 28px;
  // margin-top: 25px;
  color: var(--primaryColor);
  text-align: center;
  text-shadow: 1px 2px 1px var(--shadowBgColor);
}