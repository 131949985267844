@use "../../../../../scss/abstracts/" as *;
.themeBox {
  border-radius: 30px;
  padding: 1px;
  position: relative;
  // margin: 0 1rem;
  cursor: pointer;
}

.toggle {
  width: 18px;
  height: 18px;

  border-radius: 50%;
  position: absolute;
  left: 2px;
  top: 2px;
  transition: all 0.3s ease-in-out;
  &.darkMode {
    left: 50%;
  }
}
