@use "../../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  // @include flex(stretch, center);
  flex-direction: column;
  background-color: var(--bgc);
  color: var(--text-color) !important;
  // border: 1px solid blue;
  width: $sideBarOpenWidth;
  height: 100vh;
  position: fixed;
  top: 0;
  // left: 8px;
  transition: all 0.5s ease-in-out;
  z-index: 200;
  

  // overflow-y: auto;
  // overflow-x: hidden;
  // scrollbar-width: none; /* For Firefox */
  // -ms-overflow-style: none;  /* For Internet Explorer and Edge */

  // /* For Chrome, Safari, and Edge */
  // &::-webkit-scrollbar {
  //   display: none; /* Hide the scrollbar */
  // }

  // Landscape mode for tablets and larger mobile devices (up to 1024px)
  @media screen and (min-width: 1025px) and (orientation: landscape) {
    border-radius: 14px;
  }

  &.sidebar_close {
    width: 46px;
    overflow: auto; /* Enable scrolling */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    /* Hide scrollbar for Chrome, Safari, and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    @include tablet {
      width: 100%;
    }
  }

  @include tablet {
    width: 100vw;
    z-index: 1;
    // background-color: var(--secondaryColor);
    // color: #ebebeb !important;
  }

  &__logo {
    @include tablet {
      padding-top: 1rem;
      width: 30%;
    }
    @include mobile {
      padding-top: 0.5rem;
      width: 50%;
    }
  }

  &__menu {
    @include flex(stretch, flex-start);
    flex-direction: column;
    flex-grow: 1;
    margin: 0 4px;
    height: 88vh;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */

    /* For Chrome, Safari, and Edge */
    &::-webkit-scrollbar {
      display: none; /* Hide the scrollbar */
    }

    &__item {
      @include flex(center, flex-start);
      position: relative;
      margin-bottom: 0.4rem;
      min-height: 2.5rem;
      padding-left: 10px;
      border: transparent;
      border-radius: 5px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--primaryColor);
      }

      &.active {
        margin-top: 5px;
        font-weight: 700;
        color: white;
        background-color: var(--primaryColor);
        padding-left: 6px;
        border-radius: 5px;
        // border: 2px solid var(--primaryColor);
        // box-shadow: 0 0px 6px 0px var(--shadowBgColor);//0 4px 8px rgba(0, 0, 0, 0.2);//
        //  padding-left: 14px;
        // background-color: #000; // Optional: change background color on active
        // &::before {
        //   content: "";
        //   width: 4px;
        //   height: 100%;
        //   background-color: var(--primaryColor);
        //   border-top-right-radius: 3px;
        //   border-bottom-right-radius: 3px;
        //   position: absolute;
        //   left: 0;
        //   top: 0;
        // }
      }

      &__icon {
        @include flex(center, center);
        margin-right: 0.9rem;

        svg, img {
          width: $smallIconSize;
          height: $smallIconSize;
        }
      }
      &__txt {
        font-size: 0.85em;
        white-space: nowrap;
      }
    }
  }
}

.logout {
  align-self: flex-end;
}

html[dir="rtl"] {
  .sidebar {
    &__menu {
      &__item {
        padding-left: 0;
        // padding-right: 16px;
        // font-weight: 500;
        font-size: 18px;
        // margin-bottom: 1.5rem;
        &.active {
          padding-right: 4px;
          font-weight: 700;
          &::before {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            right: 0;
          }
        }

        &__icon {
          margin-left: 1rem;
        }
      }
    }
    &.sidebar_close .sidebar__logo {
      direction: ltr;
      width: 46px;
      margin-right: 10px;
      position: sticky;
      // overflow: hidden;
      @include tablet {
        direction: rtl;
        width: auto;
      }
    }
  }
}

.logoMobile {
  padding: 6px;
  margin-bottom: 1rem;
  margin-top: 18px;
}

.logoDesktop {
  margin: 8px 0;
  padding: 14px;
}

.borderlessBtn {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  cursor: pointer;
  font: inherit; 
}
