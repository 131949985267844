@use "../../../../../scss/abstracts/" as *;

.txtColor {
  color: var(--primaryColor);
  font-weight: bold;
  font-size: 0.8rem;
}

.amtfont {
  margin-left: 5px;
  font-size: 0.8rem;
}

.accountBalance_wrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--primaryColor);
  border-radius: 3px;
  color: var(--primaryColor);
  position: relative;
}

.headeing_label {
  position: absolute;
  top: -10px;
  left: 10px;
  background-color: var(--bgc); 
  padding: 0 5px;
  font-size: 10px;
  color: var(--primaryColor);
}

.account_balanceText {
  padding:5px;
}

.buttonWrapper {
  background-color: var(--primaryColor);
  color: #fff;
}
