@use "../../../scss/abstracts/" as *;

.topNav {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 3px solid gainsboro;
  height: 72px;
  border-radius: 12px;
  // margin-right: 5px;
  background-color: var(--bgc);
  &_left {
    @include flex(center, flex-start);
    flex-wrap: wrap;
    flex: 1;
    margin-left: 10px;
    
    &_menu_icon {
      cursor: pointer;
      @include tablet {
        display: none;
      }
    }
    &_menu {
      display: none;
      @include tablet {
        display: block;
      }
    }
  }

  &_right {
    flex: 1;
  }
}
.search_desktop_wrapper {
  flex: 1;
  @media screen and (max-width: 975px) {
    display: none;
  }
}
.search_tablet_wrapper {
  flex: 1 1 500px;
  display: none;
  @media screen and (max-width: 975px) {
    display: block;
    margin: 0.5rem 0 0;
  }
}

html[dir="rtl"] {
  .topNav_left_menu_icon {
    transform: rotate(180deg);
  }
}

.topLogo {
  width: 86px;
  margin-left: 20px;
  display: block;
}

.topLogoOpen {
  display: none;
}

.pagTitle {
  font-size: 1.4em;
  font-weight: 600;
  margin-left: 0.3rem;
  color: var(--primaryColor);
  text-shadow: 1px 1px 1px var(--shadowBgColor);
  @include mobile {
    font-size: 1.1em;
  }
  @media screen and (min-width: 600px) and (max-width: 1024px) {
    font-size: 1.1em;
  }
}

.mobileIcons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 0%;
}
