@use "../../../../scss/abstracts/" as *;

.topNavBox_right {
  margin-top: -3px;
  @include flex(center, space-evenly);
  flex: 1;
  @media screen and (max-width: 975px) {
    flex: 0;
  }
  @media screen and (max-width: 325px) {
    flex-wrap: wrap;
  }
  // @include mobile{
  //   scale: 0.8;  
  // }
}
.wrapper {
  flex: 1;
  @include flex(center, flex-end);
  // @media screen and (max-width: 975px) {
  //   justify-content: space-evenly;
  // }
  @media screen and (max-width: 325px) {
    margin-top: 0.5rem;
    justify-content: space-between;
  }
}
